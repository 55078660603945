<template>
    <div class="card-advantage">
        <span :class="icon"></span>
        <h3 class="mb-5">{{ title }}</h3>
        <hr />
        <p>
            {{ text }}
        </p>
    </div>
</template>

<script>
export default {
    name: "CardAdvantage",
    props: {
        icon: String,
        title: String,
        text: String
    }
}
</script>

<style lang="scss" scoped>
    .card-advantage {
        width: 100%;
        height: 100%;
        border: 1px solid $white;
        border-radius: 10px;
        padding: 40px;
        transition: 450ms ease-in-out;
        cursor: default;

        .icon {
            font-size: 48px;
        }

        hr {
            border-color: $white;
        }

        &:hover {
            background-color: $primary;
            border-color: $primary;
        }
    }
</style>
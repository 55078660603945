<template>
    <div class="cta-banner py-4 py-lg-5">
        <div class="container py-4 py-lg-5">
            <div class="row align-items-center">
                <div class="cta-text col-12 col-md-6 mb-5 mb-md-0">
                    <h2>{{ $t('home.ctabanner.title') }}</h2>
                    <p>{{ $t('home.ctabanner.subtitle') }}</p> 
                </div>
                <div class="cta-button col-12 col-md-6 text-center">
                    <CTAButton :text="$t('home.start')" :link="getRoute('Start')" outline />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CTAButton from '@/components/Button/CTAButton.vue';

export default {
    name: "CTABanner",
    components: {
        CTAButton
    },
    computed: {
        ...mapGetters({
            getRoute: 'ui/getRoute'
        })
    }
}
</script>

<style lang="scss" scoped>
    .cta-banner {
        background-image: linear-gradient(to right, $primary 20%, $secondary);
    }
</style>
<template>
    <div class="hero-banner" :class="$mq">
        <div class="hero-bg-image">
            <img v-parallax="0.3" :src="bgImage" alt="Olina Lab logo" />
        </div>
        <div class="hero-title text-center text-uppercase zerotoone">
            <p>{{ $t('home.title') }}</p>
            <h1>{{ $t('home.subtitle') }}</h1>
            <div class="hero-cta">
                <CTAButton
                    :text="$t('home.start')"
                    :link="getRoute('Start')"
                    outline
                    v-if="$mq !== 'desktop'"
                />
            </div>
        </div>
        <div class="hero-discover zerotoone">
            <div class="vertical-divider"></div>
            <span>
                <i class="fas fa-caret-down"></i>
            </span>
            <span>
                <i class="fas fa-caret-down"></i>
            </span>
            <span>
                <i class="fas fa-caret-down"></i>
            </span>
            <span>
                <i class="fas fa-caret-down"></i>
            </span>
            <span>
                <i class="fas fa-caret-down"></i>
            </span>
        </div>
        <div class="hero-discover right zerotoone">
            <span>
                <i class="fas fa-caret-down"></i>
            </span>
            <span>
                <i class="fas fa-caret-down"></i>
            </span>
            <span>
                <i class="fas fa-caret-down"></i>
            </span>
            <span>
                <i class="fas fa-caret-down"></i>
            </span>
            <span>
                <i class="fas fa-caret-down"></i>
            </span>
            <div class="vertical-divider"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CTAButton from '@/components/Button/CTAButton.vue';

export default {
    name: "HeroBanner",

    components: {
        CTAButton
    },

    props: {
        bgImage: {
            type: String
        }
    },

    computed: {
        ...mapGetters({
            getRoute: 'ui/getRoute'
        })
    }
}
</script>

<style lang="scss" scoped>
    .hero-banner {
        padding-top: 72px;

        background-color: $primary;
        height: 100vh;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        .hero-bg-image {
            content: "";
            opacity: 0.1;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
            }
        }

        .hero-title {
            margin-top: -72px;
            max-width: 50%;

            .hero-cta {
                position: absolute;
                bottom: 70px;
                left: 0;
                right: 0;
            }

            h1 {
                font-size: 70px;
                font-weight: bold;
            }
        }

        .hero-discover {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            position: absolute;
            left: 50px;
            bottom: 50px;

            .vertical-divider {
                height: 50vh;
                width: 2px;
                background-color: rgba(255, 255, 255, 0.5);
                margin-bottom: 20px;
            }

            i {
                position: relative;
                bottom: 0;
                transition: all .2s ease-in-out;
            }

            &.right {
                right: 50px;
                left: auto;

                .vertical-divider {
                    margin-top: 20px;
                }
            }
        }

        &.tablet {
            padding-top: 36px;

            .hero-title {
                margin-top: -36px;

                p {
                    font-size: 14px;
                }

                h1 {
                    font-size: 60px;
                }
            }
        }

        &.mobile {
            .hero-title {
                max-width: 80%;

                p {
                    font-size: 14px;
                }

                h1 {
                    font-size: 32px;
                }
            }

            .hero-discover {
                left: 20px;
                bottom: 20px;

                .vertical-divider {
                    margin-bottom: 20px;
                }

                &.right {
                    right: 20px;
                    left: auto;

                    .vertical-divider {
                        margin-top: 20px;
                    }
                }
            }
        }

        @media screen and (max-width: 320px) {
            padding-top: 0;
        }
    }
</style>
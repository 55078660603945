<template>
    <div class="advantages" :class="$mq">
        <div class="row w-100 m-0">
            <div class="col-lg-5 picture-container"></div>
            <div class="col-lg-7 mt-4 px-3 px-md-5">
                <div class="row my-5">
                    <div class="col-12">
                        <h3>{{ $t('home.advantages.title') }}</h3>
                        <div class="divider divider-default"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 mb-5" v-for="ad in advantages" :key="ad.id">
                        <CardAdvantage
                            :icon="ad.icon"
                            :title="ad.title"
                            :text="ad.text"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardAdvantage from '@/components/Home/CardAdvantage.vue'

export default {
    name: "Advantages",
    components: {
        CardAdvantage
    },
    data() {
        return {
            advantages: [
                {
                    id: 0,
                    icon: "icon mdi mdi-lightbulb-group-outline",
                    title: this.$t('home.advantages.innov.title'),
                    text: this.$t('home.advantages.innov.text')
                },
                {
                    id: 1,
                    icon: "icon mdi mdi-creation",
                    title: this.$t('home.advantages.creat.title'),
                    text: this.$t('home.advantages.creat.text')
                },
                {
                    id: 2,
                    icon: "icon mdi mdi-thumb-up",
                    title: this.$t('home.advantages.indiv.title'),
                    text: this.$t('home.advantages.indiv.text')
                },
                {
                    id: 3,
                    icon: "icon mdi mdi-speedometer",
                    title: this.$t('home.advantages.react.title'),
                    text: this.$t('home.advantages.react.text')
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .advantages {
        .picture-container {
            background-image: url('~@/assets/img/home1.png');
            background-size: cover;
            background-position: 50%;
        }

        &.tablet {
            .picture-container {
                height: 500px;
            }
        }

        &.mobile {
            .picture-container {
                height: 300px;
            }
        }
    }
</style>
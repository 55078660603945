<template>
    <div class="our-latest-work text-center" :class="$mq">
        <router-link v-for="project in projects" :key="project.id" :to="{ ...getRoute('SingleProject', project.slug) }">
            <div class="gallery">
                <img :src="project.picture" alt="" />
                <div class="caption">
                    <h3 class="caption-title">{{ project.title }}</h3>
                    <p class="cpation-content">{{ project.desc }}</p>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: "LatestWork",
    props: {
        projects: Array
    },
    computed: {
        ...mapGetters({
            getRoute: 'ui/getRoute'
        })
    }
}
</script>

<style lang="scss" scoped>
    .our-latest-work {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .gallery {
            position: relative;
            overflow: hidden;
            cursor: pointer;

            img {
                max-width: 100%;
            }

            .caption {
                color: $white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: 450ms ease-in-out;
            }

            &:hover {
                .caption {
                    opacity: 1;
                }

                img {
                    transform: scale(1.1);
                    transform-origin: 50% 50%;
                    transition: 450ms ease-in-out;
                    opacity: .5;
                }
            }
        }

        &.tablet, &.mobile {
            .gallery {
                img {
                    opacity: .5;
                }

                .caption {
                    opacity: 1;
                    width: 75%;
                }
            }
        }

        &.mobile {
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }
    }
</style>
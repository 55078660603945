<template>
    <div class="instagram" v-if="medias.length > 0">
        <carousel
            :autoWidth="false"
            :center="true"
            :dots="false"
            :items="1.23"
            :loop="true"
            :margin="15"
            :nav="false"
            :smartSpeed="500"
            class="insta-carousel"
            v-if="!instaLoading"
        >
            <a
                v-for="(picture, index) in medias"
                :key="index"
                :href="`https://www.instagram.com/p/${picture.shortcode}`"
                target="_blank"
            >
                <img
                    class="slide"
                    :src="picture.display_url"
                    :alt="picture.text"
                >
            </a>
        </carousel>
        <a
            class="instagram-container"
            :class="$mq"
            href="https://www.instagram.com/olina.lab/"
            target="_blank"
        >
            <img src="../../assets/img/icons/instagram.svg" alt="Instagram Icon" class="instagram-icon" height="40">
            <p>@olina.lab</p>
        </a>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
    name: "InstagramMobile",
    components: {
        carousel
    },
    data() {
        return {
            medias: [],
            instaLoading: true
        }
    },
    created() {
        var ig = require('instagram-scraping');

        ig.scrapeUserPage('olina.lab')
            .then(result => {
                this.medias = result.medias.slice(0, 5);
                console.dir(result);
            })
            .finally(() => {
                this.instaLoading = false;
            });
    }
}
</script>

<style lang="scss" scoped>
    .instagram {
        .insta-carousel {
            .slide {
                border-radius: 10px;
            }
        }

        .instagram-container {
            margin-top: 20px;
            text-align: center;
            display: block;
            cursor: pointer;

            .instagram-icon {
                position: relative;
                -webkit-box-shadow: 0 0 0 rgba(0,0,0,.19),0 0 0 rgba(0,0,0,.23);
                box-shadow: 0 0 0 rgba(0,0,0,.19),0 0 0 rgba(0,0,0,.23);
                -webkit-transition: all .5s;
                transition: all .5s;

                &:hover {
                    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
                    box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
                    -webkit-transform: scale(1.05);
                    transform: scale(1.05);
                }
            }

            p {
                margin-top: 10px;
            }

            &.tablet {
                font-size: 28px;
            }
        }
    }
</style>
<template>
  <div class="home" :class="$mq">
    <HeroBanner :bgImage="require('../assets/img/logos/olinawhite.png')" />
    <Services />
    <LatestWork :projects="projects.slice(0, 4)" />
    <CTABanner />
    <Advantages />
    <Instagram class="mt-5" v-if="$mq === 'desktop'" />
    <InstagramMobile v-else />
  </div>
</template>

<script>
import HeroBanner from '@/components/Banner/HeroBanner.vue'
import Services from '@/components/Home/Services.vue'
import LatestWork from '@/components/Home/LatestWork.vue'
import CTABanner from '@/components/Home/CTABanner.vue'
import Advantages from '@/components/Home/Advantages.vue'
import Instagram from '@/components/Instagram/Instagram.vue'
import InstagramMobile from '@/components/Instagram/InstagramMobile.vue'

import { ourProjects } from '@/data/projects.js'

export default {
  name: 'Home',
  components: {
    HeroBanner,
    Services,
    LatestWork,
    CTABanner,
    Advantages,
    Instagram,
    InstagramMobile
  },
  data() {
    return {
      projects: ourProjects.projects
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

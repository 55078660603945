<template>
    <div class="our-services text-center my-5">
        <h3>{{ $t('home.services.title') }}</h3>
        <div class="divider divider-default"></div>

        <div class="row row-50 justify-content-sm-center offset-custom-2 mt-5">
            <div
                class="col-sm-10 col-md-6 col-lg-4 col-xl-3 mb-5"
                v-for="(service, idx) in services"
                :key="idx"
            >
                <div class="thumbnail-classic flex-column">
                    <div class="thumbnail-classic-icon">
                        <span :class="service.icon"></span>
                    </div>
                    <div class="thumbnail-classic-caption">
                        <h6 class="thumbnail-classic-title">{{ service.title }}</h6>
                        <hr class="divider divider-default divider-sm">
                        <p class="thumbnail-classic-text">{{ service.desc }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Services",

    data() {
        return {
            services: [
                {
                    icon: 'icon mdi mdi-responsive',
                    title: this.$t('home.services.website.title'),
                    desc: this.$t('home.services.website.desc')
                },
                {
                    icon: 'icon mdi mdi-desktop-mac-dashboard',
                    title: this.$t('home.services.websol.title'),
                    desc: this.$t('home.services.websol.desc')
                },
                {
                    icon: 'icon mdi mdi-cellphone-information',
                    title: this.$t('home.services.consult.title'),
                    desc: this.$t('home.services.consult.desc')
                },
                {
                    icon: 'icon mdi mdi-basket-fill',
                    title: this.$t('home.services.ecomm.title'),
                    desc: this.$t('home.services.ecomm.desc')
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.our-services {
    max-width: 1800px;
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;

    h3 {
        font-size: 48px;
        font-size: 34px;
        line-height: 1.375;
        letter-spacing: 0.06em;
        text-transform: uppercase;
    }

    .thumbnail-classic {
        .thumbnail-classic-icon {
            margin-left: auto;
            margin-right: auto;

            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 120px;
            margin-left: auto;
            margin-right: auto;
            color: $primary;
            transition: 450ms ease-in-out;

            &:after {
                border-color: $primary;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border: 2px solid $primary;
            }
            
            &:before {
                top: 50%;
                bottom: 50%;
                left: 50%;
                right: 50%;
                opacity: 0;
                background: linear-gradient(-45deg, $secondary 15%, $primary);
            }

            &:after, &:before {
                position: absolute;
                content: "";
                display: inline-block;
                border-radius: 50%;
                transition: 450ms ease-in-out;
            }

            .icon {
                position: relative;
                font-size: 48px;
            }

            &:hover {
                color: $white;

                &:after {
                    opacity: 0;
                }

                &:before {
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 1;
                    box-shadow: 0 8px 29px rgba(63, 9, 102, 0.25);
                }
            }
        }

        .thumbnail-classic-caption {
            margin: 20px 30px;
            padding: 0 15px;

            .thumbnail-classic-title {
                font-size: 22px;
                line-height: 1.5;
                letter-spacing: 0.12em;
                text-transform: uppercase;
            }
        }
    }
}
</style>
<template>
    <div class="instagram" v-if="medias.length > 0">
        <kinesis-container class="image-container" v-if="!instaLoading">
            <img src="" alt="" class="placeholder" style="opacity: 0;" height="200">
            <!-- <kinesis-element 
                tag="img"
                :src="medias[4].display_url"
                :alt="medias[4].text"
                :strength="-20"
                type="rotate"
                transformOrigin="50% 300%"
                axis="x"
                height="200" />
            <kinesis-element 
                tag="img"
                :src="medias[3].display_url"
                :alt="medias[3].text"
                :strength="20"
                type="rotate"
                transformOrigin="50% 300%"
                axis="x"
                height="200" /> -->
            <kinesis-element 
                tag="img"
                :src="medias[2].display_url"
                :alt="medias[2].text"
                :strength="-10"
                type="rotate"
                transformOrigin="50% 300%"
                axis="x"
                height="200" />
            <kinesis-element 
                tag="img"
                :src="medias[1].display_url"
                :alt="medias[1].text"
                :strength="10"
                type="rotate"
                transformOrigin="50% 300%"
                axis="x"
                height="200" />
            <kinesis-element 
                tag="img"
                :src="medias[0].display_url"
                :alt="medias[0].text"
                axis="x"
                height="200" />
            <kinesis-element
                tag="a"
                class="instagram-container"
                href="https://www.instagram.com/olina.lab/"
                target="_blank"
            >
                <img src="../../assets/img/icons/instagram.svg" alt="Instagram Icon" class="instagram-icon" height="40">
                <p>@olina.lab</p>
            </kinesis-element>
        </kinesis-container>
    </div>
</template>

<script>
import { KinesisContainer, KinesisElement } from 'vue-kinesis'

export default {
    name: "Instagram",
    components: {
        KinesisContainer,
        KinesisElement
    },
    data() {
        return {
            medias: [],
            instaLoading: true
        }
    },
    created() {
        var ig = require('instagram-scraping');

        ig.scrapeUserPage('olina.lab')
            .then(result => {
                this.medias = result.medias.slice(0, 5);
            })
            .finally(() => {
                this.instaLoading = false;
            });
    }
}
</script>

<style lang="scss" scoped>
    .instagram {
        width: 100%;
        height: 322px;

        .image-container {
            position: relative;
            width: 200px;
            height: 100%;
            margin: auto;
            text-align: center;

            .placeholder {
                width: 100%;
                position: relative;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 10px;
            }

            .instagram-container {
                margin-top: 20px;
                text-align: center;
                display: block;
                cursor: pointer;

                .instagram-icon {
                    position: relative;
                    -webkit-box-shadow: 0 0 0 rgba(0,0,0,.19),0 0 0 rgba(0,0,0,.23);
                    box-shadow: 0 0 0 rgba(0,0,0,.19),0 0 0 rgba(0,0,0,.23);
                    -webkit-transition: all .5s;
                    transition: all .5s;

                    &:hover {
                        -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
                        box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
                        -webkit-transform: scale(1.05);
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
</style>